import React from 'react';
import Datefy from '../../../utils/Date';

const Hotlines = ({ formData, setFormData }) => {
  const { hotlines } = formData;
  const onChangeExperience = (e, i) => {
    const tempArr = [...hotlines];
    tempArr[i][e.target.name] = e.target.value;
    setFormData({
      ...formData,
      hotlines: tempArr,
    });
  };
  return (
    <div>
      <h3>
        Hotlines
        <small>
          <a
            href='#!'
            style={{ float: 'right' }}
            className='btn btn-sm btn-primary'
            onClick={(e) => {
              e.preventDefault();
              const tempArr = [
                ...hotlines,
                {
                  name: '',
                  number: '',
                  pinOrExt: '',
                },
              ];
              setFormData({
                ...formData,
                hotlines: tempArr,
              });
            }}
          >
            Add hotline
          </a>
        </small>
      </h3>
      {hotlines.map((each, index, arr) => (
        <div className='card card-body my-3' key={index}>
          <div className='row'>
            <div className='col-md-4 col-sx-12 form-group'>
              <label htmlFor={`hotlines[${index}].name`}>Name of hotline</label>
              <input
                type='text'
                value={each.name}
                onChange={(e) => onChangeExperience(e, index)}
                id={`hotlines[${index}].name`}
                name='name'
                className='form-control'
              />
            </div>
            <div className='col-md-4 col-sx-12 form-group'>
              <label htmlFor={`hotlines[${index}].number`}>
                Hotline number{' '}
              </label>
              <input
                type='text'
                value={each.number}
                onChange={(e) => onChangeExperience(e, index)}
                id={`hotlines[${index}].number`}
                name='number'
                className='form-control'
              />
            </div>
            <div className='col-md-4 col-sx-12 form-group'>
              <label htmlFor={`hotlines[${index}].pinOrExt`}>
                Pin or extension{' '}
              </label>
              <input
                type='text'
                value={each.pinOrExt}
                onChange={(e) => onChangeExperience(e, index)}
                id={`hotlines[${index}].pinOrExt`}
                name='pinOrExt'
                className='form-control'
              />
            </div>
          </div>
          <small style={{ marginTop: -10, display: 'block' }}>
            <a
              href='#!'
              onClick={(e) => {
                e.preventDefault();
                const tempArr = [...hotlines];
                tempArr.splice(index, 1);
                setFormData({
                  ...formData,
                  hotlines: tempArr,
                });
              }}
            >
              Delete
            </a>
          </small>
        </div>
      ))}
    </div>
  );
};

export default Hotlines;
