import React, { useState, createContext } from 'react';
import api from '../utils/api';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { ToastContext } from './toasts';

export const CategoryContext = createContext();

const CategorySate = ({ children }) => {
  const { addToast } = useContext(ToastContext);
  const [categories, setCategories] = useState(null);
  const [category, setCategory] = useState(null);
  const history = useHistory();

  const getCategories = async (params) => {
    try {
      const categories = await api.get('/categories', {
        params,
      });
      setCategories(categories.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getCategoryById = async (id, params) => {
    try {
      const category = await api.get('/categories/' + id, {
        params,
      });
      setCategory(category.data);
    } catch (error) {
      console.log(error);
    }
  };
  const createCategory = async (data) => {
    try {
      const category = await api.post('/admin/categories', data);
      setCategory(category.data);
      addToast('Category Created', 'success');
      getCategories();
      return category.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const deleteCategory = async (id) => {
    try {
      const category = await api.delete('/admin/categories/' + id);
      history.push('/categories');
      setCategory(null);
      addToast('Category Deleted', 'success');
      getCategories();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const updateCategory = async (id, data) => {
    try {
      const category = await api.put('/admin/categories/' + id, data);
      setCategory(category.data);
      addToast('Category Updated', 'success');
      getCategories();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  return (
    <CategoryContext.Provider
      value={{
        categories,
        category,
        getCategories,
        getCategoryById,
        setCategory,
        createCategory,
        deleteCategory,
        updateCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
};

export default CategorySate;
