import React from 'react';
import { css } from 'emotion';
import { ShortArrowLeft, ArrowLeft, ArrowRight } from '../../Layout/icons';
import { Link } from 'react-router-dom';

const ProfileHeader = ({
  didSomethingChange,
  onSubmit,
  category,
  onDelete,
}) => {
  const styles = {
    container: css({
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    }),
  };

  return (
    <div className={styles.container + ' my-3'}>
      <div>
        <Link to='/categories' className='btn btn-link '>
          <ShortArrowLeft /> Back to categories
        </Link>
        {/* <button type='button' className='btn btn-link disabled'>
          <ArrowLeft /> Previous Profile
        </button>
        <button type='button' className='btn btn-link disabled'>
          Next profile <ArrowRight />
        </button> */}
      </div>
      {category ? (
        <div className='btn-group' role='group'>
          <button
            type='submit'
            className={`btn btn-primary ${
              didSomethingChange ? '' : 'disabled'
            }`}
            onClick={(e) => onSubmit(e)}
          >
            Save changes
          </button>
          <button
            id='btnGroupDrop1'
            type='button'
            className={`btn btn-primary dropdown-toggle dropdown-toggle-split `}
            data-toggle='dropdown'
          >
            {' '}
            <span className='sr-only'>Toggle Dropdown</span>
          </button>
          <div className='dropdown-menu' aria-labelledby='btnGroupDrop1'>
            <button
              type='submit'
              className='dropdown-item text-danger'
              href='!#'
              onClick={onDelete}
            >
              Delete category
            </button>
          </div>
        </div>
      ) : (
        <button
          type='submit'
          className={`btn btn-primary ${didSomethingChange ? '' : 'disabled'}`}
          onClick={(e) => onSubmit(e)}
        >
          Save changes
        </button>
      )}
    </div>
  );
};

export default ProfileHeader;
