const devConfig = {
    SERVER_URL: 'http://localhost:5000',
    FS1_TOKEN: 'AJM3PB2CZHSR2TS89NPHUS1YVIHL2B69YO0Z2MFC2H9W3FPQFR',
    FRONTEND_URL: 'http://localhost:3001',
  };
  
  const prodConfig = {
    ...devConfig,
    SERVER_URL: 'https://api.toraheducation.org',
    FRONTEND_URL: 'https://toraheducation.org',
    LOG_ROCKET: 'nhgbao/torah-ed',
  };
  // const prodConfig = {
  //   ...devConfig,
  //   SERVER_URL: 'https://xapi.toraheducation.org',
  //   FRONTEND_URL: 'https://x.toraheducation.org',
  //   LOG_ROCKET: '',
  // };
  
  const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;
  
  export default config;