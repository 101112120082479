const Datefy = {
  setDate(date) {
    //if empty return
    if (!date) {
      return '';
    }
    // if stringified date make it for a real date
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date.toLocaleString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  },
  getDate(val) {
    return new Date(val + 'T00:00');
  },
};

export default Datefy;
