import React from 'react';
import { css } from 'emotion';
import theme from '../../theme/theme';
import { useState } from 'react';
import logo from '../../assets/img/torahEducationLogo 1.png';
import { useGlobalContext } from '../../context';
import { useLocation, useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

const Login = () => {
  const {
    auth: { loginUser, isAuthenticated },
  } = useGlobalContext();

  const location = useLocation();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  if (isAuthenticated) {
    return <Redirect to={location?.state?.from || '/profiles'} />;
  }

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    loginUser(formData.email.trim(), formData.password.trim());
  };

  const styles = {
    container: css({
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    box: css({
      maxWidth: 600,
      height: 600,
      width: '100%',
      display: 'flex',
      boxShadow: '5px 8px 6px 1px #00000030',
      '& >div:nth-child(1)': {
        width: 'auto',
        backgroundColor: theme.colors.primary,
        borderRadius: '12px 0 0 12px',
      },
      '& >div:nth-child(2)': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0 12px 12px 0',
        width: '70%',
      },
    }),
    submit: css({
      background: theme.colors.primary,
      borderColor: theme.colors.primary,
      display: 'block',
      margin: 'auto',
      minWidth: '116px',
      borderRadius: 50,
    }),
  };

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div>
          <img src={logo} alt='' />
        </div>
        <div>
          <h3>Login</h3>
          <form onSubmit={onSubmit}>
            <div className='form-group'>
              <label htmlFor='email'>Email address</label>
              <input
                autoComplete='on'
                onChange={onChange}
                value={formData.email}
                type='email'
                className='form-control'
                id='email'
                name='email'
                aria-describedby='emailHelp'
                placeholder='Enter email'
              />
              <small id='emailHelp' className='form-text text-muted'>
                We'll never share your email with anyone else.
              </small>
            </div>
            <div className='form-group'>
              <label htmlFor='password'>Password</label>
              <input
                autoComplete='on'
                onChange={onChange}
                value={formData.password}
                type='password'
                className='form-control'
                id='password'
                name='password'
                placeholder='Password'
              />
            </div>

            <button
              type='submit'
              className={`${styles.submit} btn btn-primary`}
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
