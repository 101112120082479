import axios from 'axios';
import config from '../config';
import qs from 'qs';

const api = axios.create({
  baseURL: config.SERVER_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => {
    return qs.stringify(params);
  },
});

export default api;
