import React from 'react';
import { useGlobalContext } from '../../context';
import { withRouter, Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ location, history, component: Component, ...props }) => {
  const {
    auth: { isAuthenticated, isAdmin },
  } = useGlobalContext();
  return (
    <Route
      {...props}
      render={() =>
        isAuthenticated && isAdmin ? (
          <Component />
        ) : (
          <Redirect
            to={
              isAuthenticated
                ? '/profiles'
                : { pathname: '/', state: { from: location } }
            }
          />
        )
      }
    />
  );
};

export default withRouter(AdminRoute);
