import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context';
import ArticleForm from './ArticleForm';
import { withRouter } from 'react-router-dom';

const ArticleData = ({ match, location }) => {
  const { params } = match;

  const {
    articles: { setArticle, article, getArticleById },
    categories: { categories, getCategories },
    profiles,
  } = useGlobalContext();
  const { getPublicProfiles, publicProfiles } = profiles;
  useEffect(() => {
    if (params.article_id === 'new') {
      if (!didSomethingChange) sfd(setFields());
    } else if (!article || (article && article._id !== params.article_id)) {
      getArticleById(params.article_id);
    } else if (article && !didSomethingChange) {
      sfd(setFields());
    }
  }, [article, location]);

  useEffect(() => {
    if (!categories)
      getCategories({
        select: 'name image',
      });
    if (!publicProfiles)
      getPublicProfiles({
        select: 'name',
      });
  }, []);
  useEffect(() => {
    return () => {
      setArticle(null);
    };
  }, []);

  const setFields = () => {
    const obj = {};
    fields.strings.forEach((field) => {
      obj[field] = article?.[field] || '';
      obj.status = article?.status || 'draft';
    });
    fields.arrays.forEach((field) => {
      obj[field] = article?.[field] || [];
    });
    fields.arraysOfString.forEach((field) => {
      obj[field] = article?.[field] || [''];
    });
    obj.attachments = article?.attachments || [
      {
        documentUrl: '',
        description: '',
      },
    ];
    return obj;
  };

  const fields = {
    strings: [
      'title',
      'shortDescription',
      'htmlDescription',
      'price',
      'salePrice',
      'promotionalMessage',
      'articleDuration',
      'image',
      'link',
      'website',
      'signUpButtonText',
      'signUpEmail',
      'signUpPhone',
      'articleLocation',
      //'slug',
      'articleType',
    ],
    arrays: ['languages', 'categories', 'authors'],
    arraysOfString: [],
  };

  const [formData, sfd] = useState(null);
  const [didSomethingChange, setDidSomethingChange] = useState(false);
  const setFormData = (arg) => {
    if (!didSomethingChange) setDidSomethingChange(true);
    sfd(arg);
  };

  if (formData)
    return (
      <ArticleForm
        {...{
          categories,
          formData,
          setFormData,
          article,
          publicProfiles,
          didSomethingChange,
          setDidSomethingChange,
        }}
      />
    );
  else return null;
};

export default withRouter(ArticleData);
