import React from 'react';
import { useGlobalContext } from '../../context';
import { withRouter, useLocation, Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

const PrivateRoute = ({ history, component: Component, ...props }) => {
  const location = useLocation();
  const {
    auth: { isAuthenticated },
  } = useGlobalContext();

  return (
    <Route {...props}>
      {isAuthenticated ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location },
          }}
        />
      )}
    </Route>
  );
};

export default withRouter(PrivateRoute);
