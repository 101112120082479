/* SPECIAL THANKS TO MENDY BERGER */
/* LICENSED UNDER THE MIT LICENSE */
/* COPYRIGHT 2020 */

import React, { useState } from 'react';
import { css } from 'emotion';
import config from '../../config';

export default function Fs1Input({
  disabled = false,
  accept = '',
  placeholder,
  name = '',
  value = '',
  onChange,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const uploaderToken = config.FS1_TOKEN;

  function changeValue(v) {
    setLoading(false);
    setError(false);
    onChange({
      target: {
        value: v,
        type: 'url',
        name,
      },
    });
  }

  const valueChanged = (e) => {
    let file = e.target.files[0];
    if (file) {
      setLoading(true);
      uploadFile(file)
        .then((url) => {
          changeValue(url);
        })
        .catch(() => {
          changeValue(null);
          setError(true);
        });
    } else {
      changeValue(null);
    }
    e.target.value = null;
  };

  function uploadFile(file) {
    let body = new FormData();
    body.append('ev_file', file);
    return fetch('https://fs1.cloudsnob.com/upload', {
      method: 'POST',
      headers: { upload_token: uploaderToken },
      body,
    })
      .then((res) => res.json())
      .then((res) => res.domain + res.path);
  }

  const classes = {
    input: css({
      display: 'none',
    }),
    img: css({
      display: 'block',
      maxHeight: '100%',
      maxWidth: '100%',
    }),
    label: css({
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100px',
      width: '100%',
      cursor: 'pointer',
      border: 'dashed 3px darkgray',
      boxSizing: 'border-box',
    }),
    disabledLabel: css({
      borderColor: '#f9f9f9',
    }),
  };

  return (
    <label
      className={`${classes.label} ${disabled ? classes.disabledLabel : ''}`}
    >
      <input
        type='file'
        accept={accept}
        onChange={valueChanged}
        disabled={disabled}
        className={classes.input}
      />

      {loading && (
        <svg
          fill='#cccccc'
          height='50'
          viewBox='0 0 24 24'
          width='50'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z' />
          <path d='M0 0h24v24H0z' fill='none' />
        </svg>
      )}

      {!loading && (
        <>
          {!value && !error && <span>{placeholder}</span>}

          {value && !error && (
            <img className={classes.img} src={value} alt={value} />
          )}

          {error && !value && (
            <div title='Upload Failed, Please Try Selecting The File Again'>
              <svg
                fill='#000000'
                height='100'
                viewBox='0 0 24 24'
                width='100'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M0 0h24v24H0z' fill='none' />
                <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z' />
              </svg>
            </div>
          )}

          {value && error && <span>MAJOR ERROR!! CONTACT DEVELOPER</span>}
        </>
      )}
    </label>
  );
}
