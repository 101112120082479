import React, { Fragment } from 'react';
import Datefy from '../../../utils/Date';

const Education = ({ formData, setFormData }) => {
  const { education } = formData;
  const { getDate, setDate } = Datefy;
  const onChangeEducation = (e, i) => {
    const tempArr = [...education];
    tempArr[i][e.target.name] =
      e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.type === 'date'
        ? getDate(e.target.value)
        : e.target.value;
    setFormData({
      ...formData,
      education: tempArr,
    });
  };

  return (
    <div>
      <h3>
        Education
        <small>
          <a
            href='#!'
            className='btn btn-sm btn-primary'
            style={{ float: 'right' }}
            onClick={(e) => {
              e.preventDefault();
              const tempArr = [
                ...education,
                {
                  nameOfInstitution: '',
                  description: '',
                  nameOfTrainer: '',
                  location: '',
                  current: '',
                  degreeOrCertificate: '',
                  from: '',
                  to: '',
                },
              ];
              setFormData({
                ...formData,
                education: tempArr,
              });
            }}
          >
            Add education
          </a>
        </small>
      </h3>
      {education.map((each, index, arr) => (
        <div className='card card-body my-3' key={index}>
          <div className='row'>
            <div className='col-md-4 col-sx-12'>
              <div className='form-group'>
                <label htmlFor={`education[${index}].nameOfInstitution`}>
                  Name of Institution{' '}
                </label>
                <input
                  type='text'
                  value={each.nameOfInstitution}
                  onChange={(e) => onChangeEducation(e, index)}
                  id={`education[${index}].nameOfInstitution`}
                  name='nameOfInstitution'
                  className='form-control'
                />
              </div>
              <div className='form-group'>
                <label htmlFor={`education[${index}].nameOfTrainer`}>
                  Name of Trainer{' '}
                </label>
                <input
                  type='text'
                  value={each.nameOfTrainer}
                  onChange={(e) => onChangeEducation(e, index)}
                  id={`education[${index}].nameOfTrainer`}
                  name='nameOfTrainer'
                  className='form-control'
                />
              </div>

              <div className='form-group'>
                <label htmlFor={`education[${index}].degreeOrCertificate`}>
                  Degree or certificate{' '}
                </label>
                <input
                  type='text'
                  value={each.degreeOrCertificate}
                  onChange={(e) => onChangeEducation(e, index)}
                  id={`education[${index}].degreeOrCertificate`}
                  name='degreeOrCertificate'
                  className='form-control'
                />
              </div>
              <div className='form-group'>
                <label htmlFor={`education[${index}].location`}>
                  Location{' '}
                </label>
                <input
                  type='text'
                  value={each.location}
                  onChange={(e) => onChangeEducation(e, index)}
                  id={`education[${index}].location`}
                  name='location'
                  className='form-control'
                />
              </div>
            </div>
            <div className='col-md-4 col-sx-12 form-group'>
              <label htmlFor={`education[${index}].description`}>
                Description{' '}
              </label>
              <textarea
                type='text'
                rows='12'
                value={each.description}
                onChange={(e) => onChangeEducation(e, index)}
                id={`education[${index}].description`}
                name='description'
                className='form-control'
              />
            </div>

            <div className='col-md-4 col-sx-12'>
              <div className='form-group'>
                <label htmlFor={`education[${index}].from`}>From </label>
                <input
                  type='date'
                  value={setDate(each.from)}
                  onChange={(e) => onChangeEducation(e, index)}
                  id={`education[${index}].from`}
                  name='from'
                  max={setDate(new Date())}
                  className='form-control'
                />
              </div>
              <div className='form-check'>
                <input
                  type='checkbox'
                  value={each.current}
                  onChange={(e) => onChangeEducation(e, index)}
                  id={`education[${index}].current`}
                  name='current'
                  className='form-check-input'
                />
                <label htmlFor={`education[${index}].current`}>
                  Are you currently there?{' '}
                </label>
              </div>

              <div className='form-group'>
                <label htmlFor={`education[${index}].to`}>To </label>
                <input
                  type='date'
                  value={setDate(each.to)}
                  onChange={(e) => onChangeEducation(e, index)}
                  id={`education[${index}].to`}
                  name='to'
                  min={setDate(arr[index].from)}
                  max={setDate(new Date())}
                  disabled={arr[index].current}
                  className='form-control'
                />
              </div>
            </div>
          </div>
          <small style={{ marginTop: -10, display: 'block' }}>
            <a
              href='#!'
              onClick={(e) => {
                e.preventDefault();
                const tempArr = [...education];
                tempArr.splice(index, 1);
                setFormData({
                  ...formData,
                  education: tempArr,
                });
              }}
            >
              Delete
            </a>
          </small>
        </div>
      ))}
    </div>
  );
};

export default Education;
