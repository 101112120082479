import React, { Fragment } from 'react';
import Fs1Input from '../../Layout/FsInput';

const Approach = ({ formData, onChange, setFormData }) => {
  const {
    longDescription,
    treatmentApproach,
    lettersOfRecommendations,
    accomplishments,
    professions,
    concernsTreated,
  } = formData;

  return (
    <div className='row'>
      <div className='col-md-6 col-xs-12'>
        <div className='my-3 card card-body'>
          <h5>Describe yourself</h5>
          <div className='form-group'>
            <label htmlFor='longDescription'>Description </label>
            <textarea
              className='form-control'
              id='longDescription'
              value={longDescription}
              onChange={onChange}
              rows='12'
              name='longDescription'
            />
          </div>
        </div>

        <div className='my-3 card card-body'>
          <h5>
            Treatment Approach/Methods
            <small>
              <a
                href='#!'
                className='btn btn-sm btn-primary'
                style={{ float: 'right' }}
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({
                    ...formData,
                    treatmentApproach: [...treatmentApproach, ''],
                  });
                }}
              >
                Add approach/method
              </a>
            </small>
          </h5>
          {treatmentApproach.map((value, index) => (
            <div className='form-group' key={index}>
              <textarea
                rows='2'
                className='form-control'
                type='text'
                value={value}
                onChange={(e) => {
                  const tempArr = [...treatmentApproach];
                  tempArr[index] = e.target.value;
                  setFormData({
                    ...formData,
                    treatmentApproach: tempArr,
                  });
                }}
              />
              <small className='form-text text-muted'>
                <a
                  href='#!'
                  onClick={(e) => {
                    e.preventDefault();
                    const tempArr = [...treatmentApproach];
                    tempArr.splice(index, 1);
                    setFormData({
                      ...formData,
                      treatmentApproach: tempArr,
                    });
                  }}
                >
                  Delete
                </a>
              </small>
            </div>
          ))}
        </div>
      </div>

      <div className='col-md-6 col-xs-12'>
        <div className='my-3 card card-body'>
          <h5>
            Accomplishments
            <small>
              <a
                href='#!'
                className='btn btn-sm btn-primary'
                style={{ float: 'right' }}
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({
                    ...formData,
                    accomplishments: [...accomplishments, ''],
                  });
                }}
              >
                Add accomplishment
              </a>
            </small>
          </h5>
          {accomplishments.map((value, index) => (
            <div className='form-group' key={index}>
              <textarea
                rows='2'
                className='form-control'
                type='text'
                value={value}
                onChange={(e) => {
                  const tempArr = [...accomplishments];
                  tempArr[index] = e.target.value;
                  setFormData({
                    ...formData,
                    accomplishments: tempArr,
                  });
                }}
              />
              <small className='form-text text-muted'>
                <a
                  href='#!'
                  onClick={(e) => {
                    e.preventDefault();
                    const tempArr = [...accomplishments];
                    tempArr.splice(index, 1);
                    setFormData({
                      ...formData,
                      accomplishments: tempArr,
                    });
                  }}
                >
                  Delete
                </a>
              </small>
            </div>
          ))}
        </div>
        <div className='my-3 card card-body'>
          <h5>
            Awards, license, letters of recommendations etc
            <small>
              <a
                href='#!'
                style={{ float: 'right' }}
                className='btn btn-sm btn-primary'
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({
                    ...formData,
                    lettersOfRecommendations: [
                      ...lettersOfRecommendations,
                      { description: '', documentUrl: '' },
                    ],
                  });
                }}
              >
                {' '}
                Add letter or document
              </a>
            </small>
          </h5>
          {lettersOfRecommendations.map((each, index) => (
            <Fragment key={index}>
              <div className='form-group'>
                <label htmlFor={'lettersOfRecommendations' + index}>
                  Description
                </label>
                <textarea
                  id={'lettersOfRecommendations' + index}
                  rows='2'
                  className='form-control'
                  type='text'
                  value={each.description}
                  onChange={(e) => {
                    const tempArr = [...lettersOfRecommendations];
                    tempArr[index].description = e.target.value;
                    setFormData({
                      ...formData,
                      lettersOfRecommendations: tempArr,
                    });
                  }}
                />{' '}
              </div>
              <label htmlFor={'lettersOfRecommendations' + index}>
                Attachment
              </label>
              <Fs1Input
                {...{
                  name: 'image',
                  value: each.documentUrl,
                  onChange(e) {
                    const tempArr = [...lettersOfRecommendations];
                    tempArr[index].documentUrl = e.target.value;
                    setFormData({
                      ...formData,
                      lettersOfRecommendations: tempArr,
                    });
                  },
                  placeholder: 'Attachment',
                }}
              />{' '}
              <small className='form-text text-muted'>
                <a
                  href='#!'
                  onClick={(e) => {
                    e.preventDefault();
                    const tempArr = [...lettersOfRecommendations];
                    tempArr.splice(index, 1);
                    setFormData({
                      ...formData,
                      lettersOfRecommendations: tempArr,
                    });
                  }}
                >
                  Delete
                </a>
              </small>
            </Fragment>
          ))}
        </div>
        <div className='my-3 card card-body'>
          <div className='form-group'>
            <h5>Professions</h5>
            <input
              className='form-control'
              type='text'
              name='professions'
              id='professions'
              placeholder="'Clinical Psychology', 'Psychiatry', 'Speech therapy', 'Social skills therapy', 'Optometry', 'קריאה ספיציליסט,,"
              value={professions}
              onChange={onChange}
              onChange={(e) => {
                const tempArr = [...e.target.value.split(',')];
                setFormData({
                  ...formData,
                  professions: tempArr,
                });
              }}
            />
            <small className='form-text text-muted'>
              Type a comma after each entry
            </small>
          </div>
        </div>

        <div
          className='my-3 card card-body'
          style={{
            maxHeight: 550,
            overflowY: 'scroll',
            position: 'relative',
            paddingTop: 0,
          }}
        >
          <h5
            style={{
              position: 'sticky',
              top: 0,
              background: '#fff',
              paddingTop: '1.25rem',
            }}
          >
            Concerns successfully treated
            <small>
              <a
                href='#!'
                className='btn btn-sm btn-primary'
                style={{ float: 'right' }}
                onClick={(e) => {
                  e.preventDefault();
                  setFormData({
                    ...formData,
                    concernsTreated: [...concernsTreated, ''],
                  });
                }}
              >
                Add
              </a>
            </small>
          </h5>
          {concernsTreated.map((value, index) => (
            <div className='form-group' key={index}>
              <textarea
                rows='2'
                className='form-control'
                type='text'
                value={value}
                onChange={(e) => {
                  const tempArr = [...concernsTreated];
                  tempArr[index] = e.target.value;
                  setFormData({
                    ...formData,
                    concernsTreated: tempArr,
                  });
                }}
              />
              <small className='form-text text-muted'>
                <a
                  href='#!'
                  onClick={(e) => {
                    e.preventDefault();
                    const tempArr = [...concernsTreated];
                    tempArr.splice(index, 1);
                    setFormData({
                      ...formData,
                      concernsTreated: tempArr,
                    });
                  }}
                >
                  Delete
                </a>
              </small>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Approach;
