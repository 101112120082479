import React, { useState, createContext } from 'react';
import api from '../utils/api';
import { useEffect } from 'react';

export const AuthContext = createContext();

const AuthState = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [isAdmin, setIsAdmin] = useState(true);

  const getUserProfile = async () => {
    const profile = await api.get('/auth/me');
    if (profile.status === 200) {
      setUserProfile(profile.data);
      setIsAuthenticated(true);
      setIsAdmin(profile?.data?.role === 'admin');
    }
  };

  const loginUser = async (email, password) => {
    const res = await api.post('/auth/login', { email, password });
    if (res.status === 200) setIsAuthenticated(true);
  };

  const logout = async () => {
    const res = await api.get('/auth/logout');
    if (res.status === 200) {
      setIsAuthenticated(false);
      setUserProfile(null);
    }
  };

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        setIsAuthenticated(false);
        setUserProfile(null);
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    getUserProfile();
  }, [isAuthenticated]);
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userProfile,
        getUserProfile,
        loginUser,
        logout,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
