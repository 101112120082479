import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { css } from 'emotion';
import theme from '../../theme/theme';
import { useGlobalContext } from '../../context';
import { useEffect } from 'react';

const CategoryList = ({ history }) => {
  const {
    categories: { categories, getCategories },
  } = useGlobalContext();
  useEffect(() => {
    if (!categories)
      getCategories({
        select: 'name image profileCount',
      });
  }, []);

  const styles = {
    button: css({
      padding: '6px 18px',
      color: '#fff',
      backgroundColor: theme.colors.primary,
      borderRadius: 12,
    }),
    headerContainer: css({
      display: 'flex',
      justifyContent: 'end',
      maxWidth: 400,
      '& h3': {
        color: theme.colors.primary,
        marginLeft: 0,
      },
      '& > *': {
        marginLeft: 18,
      },
    }),
    imgWrapper: css({
      maxWidth: 36,
      borderRadius: '50%',
      maxHeight: 36,
      overflow: 'hidden',
      display: 'inline-block',
    }),
    img: css({
      maxWidth: '100%',
    }),
    tr: css({
      cursor: 'pointer',
      transition: '0.2s',
      '&:hover': {
        backgroundColor: '#ddd',
      },
    }),
  };
  return (
    <div>
      <Link
        to='/categories/new'
        className={`float-right btn  ${styles.button}`}
      >
        ADD NEW
      </Link>
      <div className={`${styles.headerContainer} mt-3`}>
        <h3>Categories</h3>
        <a href='#!' className='disabled'>
          Show Filters
        </a>
      </div>

      <table className='table'>
        <thead>
          <tr>
            <th scope='col'></th>
            <th scope='col'>Name</th>
          </tr>
          {categories &&
            Array.isArray(categories) &&
            categories.map((category, i) => (
              <tr
                key={i}
                onClick={() => history.push('/categories/' + category._id)}
                className={styles.tr}
              >
                <td>
                  <div className={styles.imgWrapper}>
                    <img
                      src={category.image}
                      className={`${styles.img}`}
                      alt=''
                    />
                  </div>
                </td>
                <td>{category.name}</td>
              </tr>
            ))}
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
};

export default withRouter(CategoryList);
