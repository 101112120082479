import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context';
import CategoryForm from './CategoryForm';
import { withRouter } from 'react-router-dom';

const CategoryData = ({ match, location }) => {
  const { params } = match;
  const {
    categories: { setCategory, category, getCategoryById },
  } = useGlobalContext();

  useEffect(() => {
    if (params.category_id === 'new') {
      if (!didSomethingChange) setTextFields(setFields());
    } else if (!category || (category && category._id !== params.category_id)) {
      getCategoryById(params.category_id);
    } else if (category && !didSomethingChange) {
      setTextFields(setFields());
    }
  }, [category, location]);

  useEffect(() => {
    return () => {
      setCategory(null);
    };
  }, []);

  const [didSomethingChange, setDidSomethingChange] = useState(false);

  const setFields = () => {
    const obj = {};
    fields.strings.forEach((field) => {
      obj[field] = category?.[field] || '';
    });
    return obj;
  };

  const fields = {
    strings: ['name', 'description', 'image'],
  };

  const [textFields, setTextFields] = useState(null);

  if (textFields)
    return (
      <CategoryForm
        {...{
          textFields,
          setTextFields,
          category,
          didSomethingChange,
          setDidSomethingChange,
        }}
      />
    );
  else return null;
};

export default withRouter(CategoryData);
