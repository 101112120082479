import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context';
import ProfileForm from './ProfileForm';
import { withRouter } from 'react-router-dom';

const ProfileData = ({ match, location }) => {
  const { params } = match;

  const {
    profiles: { setProfile, profile, getProfileById },
    categories: { categories, getCategories },
  } = useGlobalContext();
  useEffect(() => {
    if (params.profile_id === 'new') {
      if (!didSomethingChange) sfd(setFields());
    } else if (!profile || (profile && profile._id !== params.profile_id)) {
      getProfileById(params.profile_id);
    } else if (profile && !didSomethingChange) {
      sfd(setFields());
    }
  }, [profile, location]);

  useEffect(() => {
    if (!categories)
      getCategories({
        select: 'name image',
      });
  }, []);

  useEffect(() => {
    return () => {
      setProfile(null);
    };
  }, []);

  const setFields = () => {
    const obj = {};
    fields.strings.forEach((field) => {
      obj[field] = profile?.[field] || '';
      obj.status = profile?.status || 'draft';
      obj.onlineConsultation = profile?.onlineConsultation || false;
      obj.phoneConsultation = profile?.phoneConsultation || false;
    });
    fields.arrays.forEach((field) => {
      obj[field] = profile?.[field] || [];
    });
    fields.arraysOfString.forEach((field) => {
      obj[field] = profile?.[field] || [''];
    });
    obj.primaryLocation = profile?.primaryLocation || {
      locationName: '',
      street: '',
      zip: '',
      city: '',
      state: '',
      country: '',
      locationPhoneNumber: '',
    };
    obj.education = profile?.education || [
      {
        nameOfInstitution: '',
        description: '',
        nameOfTrainer: '',
        location: '',
        current: '',
        degreeOrCertificate: '',
        from: '',
        to: '',
      },
    ];
    obj.experience = profile?.experience || [
      {
        from: '',
        current: '',
        to: '',
        jobTitle: '',
        employer: '',
        description: '',
        location: '',
      },
    ];
    obj.lettersOfRecommendations = profile?.lettersOfRecommendations || [
      {
        documentUrl: '',
        description: '',
      },
    ];
    return obj;
  };

  const fields = {
    strings: [
      'name',
      'title',
      'founderOf',
      'smallMessage',
      'longDescription',
      'degreeOrCertificate',
      'practiceSince',
      'email',
      'primaryPhone',
      'mobilePhone',
      'fax',
      'website',
      'yiddishName',
      'scheduleLink',
      'therapistGender',
      'licenseNumber',
      'licensedIn',
      'awards',
      'image',
      'primaryLocation',
      // 'company',
      //'licenseStatus,
      // 'slug',
    ],
    arrays: [
      'hotlines',
      'secondaryLocations',
      'professions',
      'languages',
      'agesTreating',
      'clientsGender',
      'sessionFormats',
      'billingAndInsurance',
    ],
    arraysOfString: [
      'treatmentApproach',
      'accomplishments',
      'affiliations',
      'categories',
      'concernsTreated',
    ],
  };

  const [formData, sfd] = useState(null);
  const [didSomethingChange, setDidSomethingChange] = useState(false);
  const setFormData = (arg) => {
    if (!didSomethingChange) setDidSomethingChange(true);
    sfd(arg);
  };

  if (formData)
    return (
      <ProfileForm
        {...{
          categories,
          formData,
          setFormData,
          profile,
          didSomethingChange,
          setDidSomethingChange,
        }}
      />
    );
  else return null;
};

export default withRouter(ProfileData);
