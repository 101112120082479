import React from 'react';
import { css } from 'emotion';
import { useGlobalContext } from '../../context';
import { useEffect } from 'react';

const Loading = () => {
  const {
    loading: { loading },
  } = useGlobalContext();
  useEffect(() => {
    if (loading) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
    return () => (document.body.style.overflow = 'auto');
  }, [loading]);
  const styles = {
    overlay: css({
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
  };
  if (!loading) return null;
  return (
    <div className={`${styles.overlay}`}>
      <div className='d-flex justify-content-center'>
        <div className='spinner-border' role='status' style={{ color: '#fff' }}>
          <span className='sr-only'>Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Loading;
