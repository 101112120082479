export default function sanitized(object, original) {
  switch (typeof object) {
    case 'object':
      const keys = Object.keys(object || {}).filter((key) => {
        const value = sanitized(object[key], original?.[key]);
        if (value === undefined && original?.[key] === undefined) {
          if (Array.isArray(object)) {
            object.splice(key, 1);
          } else {
            delete object[key];
          }
        }
        return value !== undefined;
      });
      return keys.length > 0 || original?.length > 0
        ? filterObj(object)
        : undefined;

    case 'string':
      return object.length > 0 || original?.length > 0 ? object : undefined;

    default:
      return object;
  }
}

function filterObj(obj) {
  let result = Array.isArray(obj) ? [] : {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      result[key] = obj[key];
    }
  }

  return result;
}
