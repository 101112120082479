import React from 'react';
import Profile from './Profile';
import Approach from './Approach';
import Details from './Details';

const Tabs = ({ formData, setFormData, onChange, categories }) => {
  return (
    <>
      <ul className='nav nav-tabs' id='myTab' role='tablist'>
        <li className='nav-item' role='presentation'>
          <a
            className='nav-link active'
            id='home-tab'
            data-toggle='tab'
            href='#profile'
            role='tab'
            aria-controls='home'
            aria-selected='true'
          >
            Profile
          </a>
        </li>
        <li className='nav-item' role='presentation'>
          <a
            className='nav-link'
            id='profile-tab'
            data-toggle='tab'
            href='#approach'
            role='tab'
            aria-controls='profile'
            aria-selected='false'
          >
            Approach
          </a>
        </li>
        <li className='nav-item' role='presentation'>
          <a
            className='nav-link'
            id='contact-tab'
            data-toggle='tab'
            href='#details'
            role='tab'
            aria-controls='contact'
            aria-selected='false'
          >
            Details
          </a>
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
        <div
          className='tab-pane fade show active'
          id='profile'
          role='tabpanel'
          aria-labelledby='home-tab'
        >
          <Profile {...{ formData, setFormData, onChange, categories }} />
        </div>
        <div
          className='tab-pane fade'
          id='approach'
          role='tabpanel'
          aria-labelledby='profile-tab'
        >
          <Approach {...{ formData, setFormData, onChange }} />
        </div>
        <div
          className='tab-pane fade'
          id='details'
          role='tabpanel'
          aria-labelledby='contact-tab'
        >
          <Details {...{ formData, onChange, setFormData }} />
        </div>
      </div>
    </>
  );
};

export default Tabs;
