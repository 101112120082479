import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { css } from 'emotion';
import theme from '../../theme/theme';
import { useGlobalContext } from '../../context';
import { useEffect } from 'react';
import Select from 'react-select';

const ArticleList = ({ history, location }) => {
  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const setOptions = (list) => {
    const options = list.map((e) => {
      const obj = {};
      obj.value = e._id;
      obj.label = e.name;
      return obj;
    });
    return options;
  };

  const query = useQuery();

  const {
    articles: { articles, getArticles, searchArticles },
    categories: { categories, getCategories },
  } = useGlobalContext();

  const [searchTerm, setSearchTerm] = useState(query.get('q') || '');

  const [selectedCategories, setSelectedCategories] = useState([]);

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (!categories) getCategories();
    const params = {
      select: 'image status categories title',
      populate: 'categories',
    };

    if (query.get('categories') && categories) {
      const list = categories.filter((e) =>
        query.get('categories').split(' ').includes(e._id)
      );
      setSelectedCategories(setOptions(list));
      params.categories = {
        in: query.get('categories').split(' '),
      };
    }

    if (query.get('q')) {
      searchArticles(query.get('q'), params);
    } else if (!articles || !query.get('q')) {
      getArticles(params);
    }
  }, [location.search, categories]);

  const styles = {
    button: css({
      padding: '6px 18px',
      color: '#fff',
      backgroundColor: theme.colors.primary,
      borderRadius: 12,
    }),
    headerContainer: css({
      display: 'flex',
      justifyContent: 'end',
      maxWidth: 250,
      '& h3': {
        color: theme.colors.primary,
        marginLeft: 0,
      },
      '& > *': {
        marginLeft: 18,
      },
    }),
    imgWrapper: css({
      maxWidth: 36,
      borderRadius: '50%',
      maxHeight: 36,
      overflow: 'hidden',
      display: 'inline-block',
    }),
    img: css({
      maxWidth: '100%',
    }),
    tr: css({
      cursor: 'pointer',
      transition: '0.2s',
      '&:hover': {
        backgroundColor: '#ddd',
      },
    }),
  };

  return (
    <div>
      <Link to='/articles/new' className={`float-right btn  ${styles.button}`}>
        ADD NEW
      </Link>
      <div className={`${styles.headerContainer} mt-3`}>
        <h3>Articles</h3>

        <a
          href='#!'
          className='disabled'
          onClick={(e) => {
            e.preventDefault();
            setShowFilters(!showFilters);
          }}
        >
          {showFilters ? 'Hide filters' : 'Show filters'}
        </a>
      </div>
      {showFilters && (
        <>
          <div className='form-group'>
            <input
              type='search'
              className='form-control'
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                history.push('/profiles?q=' + e.target.value);
              }}
              placeholder='Search articles'
            />
          </div>
          <div>
            {categories && (
              <Select
                defaultValue={selectedCategories}
                isMulti
                placeholder='Filter by category'
                options={setOptions(categories)}
                onChange={(val) => {
                  if (val?.length < categories.length || isNaN(val?.length)) {
                    let str = '';
                    if (!val?.length) {
                      query.delete('categories');
                    } else {
                      val.map((e) => {
                        str += e.value + ' ';
                      });
                      if (str.endsWith(' ')) {
                        str = str.slice(0, -1);
                      }
                      query.set('categories', str);
                    }
                    history.push('/articles?' + query.toString());
                  }
                }}
              />
            )}
          </div>
        </>
      )}

      <table className='table'>
        <thead>
          <tr>
            <th scope='col'></th>
            <th scope='col'>Name</th>
            <th scope='col'>Status</th>
            <th scope='col'>Category</th>
          </tr>
          {articles &&
            Array.isArray(articles) &&
            articles.map((article, i) => (
              <tr
                key={i}
                onClick={() => history.push('/articles/' + article._id)}
                className={styles.tr}
              >
                <td>
                  <div className={styles.imgWrapper}>
                    <img
                      src={article.image}
                      className={`${styles.img}`}
                      alt=''
                    />
                  </div>
                </td>
                <td>{article.title}</td>
                <td>{article.status}</td>
                <td>{article.categories?.[0]?.name}</td>
              </tr>
            ))}
        </thead>
        <tbody></tbody>
      </table>
    </div>
  );
};

export default withRouter(ArticleList);
