import React, { useState, createContext, useEffect } from 'react';
import api from '../utils/api';

export const LoadingContext = createContext();

const LoadingState = ({ children }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api.interceptors.request.use(
      function (config) {
        setLoading(true);
        return config;
      },
      function (error) {
        setLoading(false);
        console.log(error);
        return Promise.reject(error);
      }
    );

    api.interceptors.response.use(
      function (response) {
        setLoading(false);
        return response;
      },
      function (error) {
        setLoading(false);
        console.log(error);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <LoadingContext.Provider value={{ loading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingState;
