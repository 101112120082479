import React, { useEffect } from 'react';
import ProfileHeader from './ProfileHeader';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../context';
import Tabs from './Tabs';
import sanitizeObj from '../../../utils/sanitizedObj';

const ProfileForm = ({
  profile,
  categories,
  formData,
  setFormData,
  didSomethingChange,
  setDidSomethingChange,
}) => {
  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const { status } = formData;

  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (!didSomethingChange && profile && params.profile_id === 'new')
      history.push('/profiles/' + profile._id);
  }, [didSomethingChange]);
  const {
    profiles: { updateProfile, createProfile, profile: originalProfile },
  } = useGlobalContext();

  const onSubmit = async (e, status) => {
    e.preventDefault();
    const clone = JSON.parse(JSON.stringify({ ...formData, status }));

    let data = sanitizeObj(clone, originalProfile);

    let res;
    if (profile) {
      res = await updateProfile(profile._id, data);
    } else {
      res = await createProfile(data);
    }
    if (res) {
      setDidSomethingChange(false);
    }
  };

  return (
    <form>
      <div className='container'>
        <ProfileHeader
          {...{
            status,
            didSomethingChange,
            profile,
            onSubmit,
          }}
        />
        <Tabs
          {...{
            formData,
            onChange,
            setFormData,
            categories,
          }}
        />

        <Prompt
          when={didSomethingChange}
          message={(location) => `Do you want to leave without saving?`}
        />
      </div>
    </form>
  );
};

export default ProfileForm;
