import React, { useState, createContext } from 'react';
import api from '../utils/api';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from './auth';
import { ToastContext } from './toasts';
import { withRouter } from 'react-router-dom';

export const CourseContext = createContext();

const CourseState = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { addToast } = useContext(ToastContext);
  const [courses, setCourses] = useState(null);
  const [course, setCourse] = useState(null);

  const getCourses = async (
    params = { select: 'image status categories title', populate: 'categories' }
  ) => {
    try {
      const courses = await api.get('/admin/courses', {
        params,
      });
      setCourses(courses.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getCourseById = async (id) => {
    try {
      const course = await api.get('/admin/courses/' + id);
      setCourse(course.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCourse = async (id, data) => {
    try {
      const course = await api.put('/admin/courses/' + id, data);
      setCourse(course.data);
      addToast('Course Updated', 'success');
      getCourses();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const createCourse = async (data) => {
    try {
      const course = await api.post('/admin/courses', data);
      addToast('Course Created', 'success');
      getCourses();
      setCourse(course.data);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const searchCourses = async (
    term,
    params = { select: 'image status categories title', populate: 'categories' }
  ) => {
    try {
      const courses = await api.get('/admin/courses/search', {
        params: {
          ...params,
          q: term,
        },
      });
      setCourses(courses.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setCourses(null);
      setCourse(null);
    }
  }, [isAuthenticated]);

  return (
    <CourseContext.Provider
      value={{
        courses,
        getCourses,
        getCourseById,
        searchCourses,
        course,
        updateCourse,
        createCourse,
        setCourse,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

export default withRouter(CourseState);
