import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../context';
import CourseForm from './CourseForm';
import { withRouter } from 'react-router-dom';

const CourseData = ({ match, location }) => {
  const { params } = match;

  const {
    courses: { setCourse, course, getCourseById },
    categories: { categories, getCategories },
    profiles,
  } = useGlobalContext();
  const { getPublicProfiles, publicProfiles } = profiles;
  useEffect(() => {
    if (params.course_id === 'new') {
      if (!didSomethingChange) sfd(setFields());
    } else if (!course || (course && course._id !== params.course_id)) {
      getCourseById(params.course_id);
    } else if (course && !didSomethingChange) {
      sfd(setFields());
    }
  }, [course, location]);

  useEffect(() => {
    if (!categories)
      getCategories({
        select: 'name image',
      });
    if (!publicProfiles)
      getPublicProfiles({
        select: 'name',
      });
  }, []);
  useEffect(() => {
    return () => {
      setCourse(null);
    };
  }, []);

  const setFields = () => {
    const obj = {};
    fields.strings.forEach((field) => {
      obj[field] = course?.[field] || '';
      obj.status = course?.status || 'draft';
    });
    fields.arrays.forEach((field) => {
      obj[field] = course?.[field] || [];
    });
    fields.arraysOfString.forEach((field) => {
      obj[field] = course?.[field] || [''];
    });
    obj.attachments = course?.attachments || [
      {
        documentUrl: '',
        description: '',
      },
    ];
    return obj;
  };

  const fields = {
    strings: [
      'title',
      'shortDescription',
      'htmlDescription',
      'price',
      'salePrice',
      'promotionalMessage',
      'courseDuration',
      'image',
      'link',
      'website',
      'signUpButtonText',
      'signUpEmail',
      'signUpPhone',
      'courseLocation',
      //'slug',
      'courseType',
    ],
    arrays: ['languages', 'categories', 'authors'],
    arraysOfString: [],
  };

  const [formData, sfd] = useState(null);
  const [didSomethingChange, setDidSomethingChange] = useState(false);
  const setFormData = (arg) => {
    if (!didSomethingChange) setDidSomethingChange(true);
    sfd(arg);
  };

  if (formData)
    return (
      <CourseForm
        {...{
          categories,
          formData,
          setFormData,
          course,
          publicProfiles,
          didSomethingChange,
          setDidSomethingChange,
        }}
      />
    );
  else return null;
};

export default withRouter(CourseData);
