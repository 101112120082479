import React from 'react';
import Education from './Education';
import Experience from './Experience';
import Hotlines from './Hotlines';
import { Fragment } from 'react';

const Details = ({ formData, onChange, setFormData }) => {
  const {
    therapistGender,
    licenseNumber,
    licensedIn,
    onlineConsultation,
    phoneConsultation,
    affiliations,

    agesTreating,
    clientsGender,
    sessionFormats,
    languages,
    billingAndInsurance,
  } = formData;

  const ucFirst = (str) => {
    if (typeof str !== 'string' || str?.length < 1) return str;
    return str[0].toUpperCase() + str.slice(1);
  };

  const availableLanguages = ['Yiddish', 'English', 'Hebrew'];

  const languageOptions = (filter = () => true) =>
    availableLanguages &&
    ['Please select a language', ...availableLanguages]
      .filter(filter)
      .map((e, i) => (
        <option key={i} value={e}>
          {e}
        </option>
      ));

  return (
    <>
      <div className='my-3 card card-body'>
        <div className='row'>
          <div className='col-md-6 col-xs-12'>
            <h5 style={{ marginBottom: 40 }}>Practice details</h5>
            <div className='form-group'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='onlineConsultation'
                  name='onlineConsultation'
                  checked={onlineConsultation}
                  onChange={onChange}
                />
                <label
                  className='form-check-label'
                  htmlFor='onlineConsultation'
                >
                  Do you offer online consultation?
                </label>
              </div>
            </div>
            <div className='form-group'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='phoneConsultation'
                  name='phoneConsultation'
                  checked={phoneConsultation}
                  onChange={onChange}
                />
                <label className='form-check-label' htmlFor='phoneConsultation'>
                  Do you offer phone consultation?
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label htmlFor='licenseNumber'>License number</label>
                <input
                  type='text'
                  className='form-control'
                  id='licenseNumber'
                  value={licenseNumber}
                  onChange={onChange}
                  name='licenseNumber'
                />
              </div>

              <div className='form-group col-md-6'>
                <label htmlFor='licensedIn'>Licence issuer</label>
                <input
                  type='text'
                  className='form-control'
                  id='licensedIn'
                  value={licensedIn}
                  onChange={onChange}
                  name='licensedIn'
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='therapistGender'>Gender</label>
                <select
                  className='form-control'
                  id='therapistGender'
                  defaultValue={therapistGender}
                  onChange={onChange}
                  name='therapistGender'
                >
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
            </div>
            <div className='my-3 card card-body'>
              <h5>
                Languages
                <small>
                  <a
                    href='#!'
                    className='btn btn-sm btn-primary'
                    style={{ float: 'right' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setFormData({
                        ...formData,
                        languages: [...languages, ''],
                      });
                    }}
                  >
                    Add language
                  </a>
                </small>
              </h5>
              {languages.map((value, index) => (
                <Fragment key={index}>
                  <select
                    className='custom-select'
                    value={value}
                    onChange={(e) => {
                      const tempArr = [...languages];
                      tempArr[index] = e.target.value;
                      setFormData({
                        ...formData,
                        languages: tempArr,
                      });
                    }}
                  >
                    {languageOptions(
                      (e) => !languages.includes(e) || value === e
                    )}
                  </select>
                  <small className='form-text text-muted'>
                    <a
                      href='#!'
                      onClick={(e) => {
                        e.preventDefault();
                        const tempArr = [...languages];
                        tempArr.splice(index, 1);
                        setFormData({
                          ...formData,
                          languages: tempArr,
                        });
                      }}
                    >
                      Delete
                    </a>
                  </small>
                </Fragment>
              ))}
            </div>
            {/* <div className='form-group'>
              <label htmlFor='languages'>What languages to you speak</label>
              <input
                className='form-control'
                type='text'
                name='languages'
                id='languages'
                placeholder="'Yiddish', 'English'"
                value={languages}
                onChange={({ target }) => {
                  const tempArr = target.value
                    .split(',')
                    .map((e) => ucFirst(e));
                  setFormData({
                    ...formData,
                    languages: tempArr,
                  });
                }}
              />
              <small className='form-text text-muted'>
                Type a comma after each entry
              </small>
            </div> */}
          </div>
          <div className='col-md-6 col-xs-12'>
            <div className='form-group'>
              <label htmlFor='agesTreating'>What ages are you treating</label>
              <input
                className='form-control'
                type='text'
                name='agesTreating'
                placeholder="'Teens', 'Kids', 'Adults', etc."
                id='agesTreating'
                value={agesTreating}
                onChange={({ target }) =>
                  setFormData({
                    ...formData,
                    agesTreating: target.value.split(','),
                  })
                }
              />
              <small className='form-text text-muted'>
                Type a comma after each entry
              </small>
            </div>
            <div className='form-group'>
              <label htmlFor='clientsGender'>
                What gender group are you seeing
              </label>
              <input
                className='form-control'
                type='text'
                name='clientsGender'
                id='clientsGender'
                placeholder="'Men', 'Women',"
                value={clientsGender}
                onChange={({ target }) =>
                  setFormData({
                    ...formData,
                    clientsGender: target.value.split(','),
                  })
                }
              />
              <small className='form-text text-muted'>
                Type a comma after each entry
              </small>
            </div>
            <div className='form-group'>
              <label htmlFor='sessionFormats'>
                What is your session format
              </label>
              <input
                className='form-control'
                type='text'
                name='sessionFormats'
                id='sessionFormats'
                placeholder="'Group therapy', 'Individual', 'Family therapy', 'One on One' etc."
                value={sessionFormats}
                onChange={({ target }) =>
                  setFormData({
                    ...formData,
                    sessionFormats: target.value.split(','),
                  })
                }
              />
              <small className='form-text text-muted'>
                Type a comma after each entry
              </small>
            </div>
            <div className='form-group'>
              <label htmlFor='billingAndInsurance'>
                Billing and Insurance{' '}
              </label>
              <input
                className='form-control'
                type='text'
                name='billingAndInsurance'
                id='billingAndInsurance'
                placeholder="'All insurances accepted', 'Ask for details', etc."
                value={billingAndInsurance}
                onChange={({ target }) =>
                  setFormData({
                    ...formData,
                    billingAndInsurance: target.value.split(','),
                  })
                }
              />
              <small className='form-text text-muted'>
                Type a comma after each entry
              </small>
            </div>
            <div className='form-group'>
              <label htmlFor='affiliations'>Affiliations</label>
              <input
                className='form-control'
                type='text'
                name='affiliations'
                id='affiliations'
                placeholder="'Company', 'Organization', 'Institution', etc."
                value={affiliations}
                onChange={({ target }) =>
                  setFormData({
                    ...formData,
                    affiliations: target.value.split(','),
                  })
                }
              />
              <small className='form-text text-muted'>
                Type a comma after each entry
              </small>
            </div>
          </div>
        </div>
      </div>
      <Hotlines {...{ formData, setFormData }} />
      <Education {...{ formData, setFormData }} />
      <Experience {...{ formData, setFormData }} />
    </>
  );
};

export default Details;
