import React, { Fragment, useEffect, useRef } from 'react';
import ArticleHeader from './ArticleHeader';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../context';
import sanitizeObj from '../../../utils/sanitizedObj';
import Fs1Input from '../../Layout/FsInput';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import TextEditor from '../../Layout/TextEditor';
import config from '../../../config';

const ArticleForm = ({
  article,
  categories: categoriesList,
  publicProfiles,
  formData,
  setFormData,
  didSomethingChange,
  setDidSomethingChange,
}) => {
  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    if (!didSomethingChange && article && params.article_id === 'new')
      history.push('/articles/' + article._id);
  }, [didSomethingChange]);

  const {
    title,
    shortDescription,
    htmlDescription,
    price,
    salePrice,
    promotionalMessage,
    articleDuration,
    image,
    articleType,
    authors,
    languages,
    link,
    articleLocation,
    signUpButtonText,
    signUpPhone,
    signUpEmail,
    website,
    categories,
    attachments,
    status,
  } = formData;

  useEffect(() => {
    if (!privateProfiles) {
      //if there is no profiles, and this component is the one setting profiles, then we also need to clear it. Because if a different component checks if there is already profiles b4 making the getProfiles call, they will get true, but the wrong selected fields. Since we only select name and they might need more fields, so the fact the there are profiles will cause them not to get profiles with there needed fields
      getProfiles({ select: 'name' });
      return () => setProfiles(null);
    }
  }, []);

  const availableLanguages = ['Yiddish', 'English', 'Hebrew'];

  const setOptions = (list) => {
    const options = list.map((e) => {
      const obj = {};
      obj.value = typeof e === 'string' ? e : e?._id;
      obj.label = typeof e === 'string' ? e : e?.name;
      return obj;
    });
    return options;
  };
  const {
    articles: { updateArticle, createArticle, article: originalArticle },
    // privateProfiles are the profiles that belong to the current logged in user, so it could potentially also be unpbulished profiles which are not part of publicProfiles
    profiles: { profiles: privateProfiles, getProfiles, setProfiles },
  } = useGlobalContext();

  const profileOptions =
    privateProfiles?.length && publicProfiles?.length
      ? [
          ...publicProfiles,
          ...privateProfiles.filter(
            (e) => !publicProfiles.map((e) => e._id).includes(e._id)
          ),
        ]
      : [];

  const onSubmit = async (e, status) => {
    e.preventDefault();
    const clone = JSON.parse(JSON.stringify({ ...formData, status }));
    let data = sanitizeObj(clone, originalArticle);
    let res;
    console.log(data);
    if (article) {
      res = await updateArticle(article._id, data);
    } else {
      res = await createArticle(data);
    }
    if (res) {
      setDidSomethingChange(false);
    }
  };
  return (
    <form>
      <div className='container'>
        <ArticleHeader
          {...{
            status,
            didSomethingChange,
            article,
            onSubmit,
          }}
        />

        <div className='row'>
          <div className='col-md-8 col-xs-12'>
            <h3>Article</h3>
            <div className='form-group'>
              <label htmlFor='title'>Article title </label>
              <input
                className='form-control'
                id='title'
                value={title}
                onChange={onChange}
                name='title'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='shortDescription'>Short Description </label>
              <textarea
                className='form-control'
                id='shortDescription'
                value={shortDescription}
                onChange={onChange}
                rows='2'
                name='shortDescription'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='Authors'>Authors </label>
              <Select
                isMulti
                defaultValue={setOptions(authors || [])}
                placeholder='Authors of this article'
                options={setOptions(profileOptions || [])}
                onChange={(val) => {
                  const tempArr = val?.map((e) => e.value) || [];
                  onChange({
                    target: { name: 'authors', value: tempArr },
                  });
                }}
              />
            </div>
            <div className='form-group'>
              <label htmlFor='languages'>Languages </label>
              <Select
                isMulti
                defaultValue={setOptions(languages || [])}
                placeholder='Languages'
                options={setOptions(availableLanguages)}
                onChange={(val) => {
                  const tempArr = val?.map((e) => e.value) || [];
                  onChange({
                    target: { name: 'languages', value: tempArr },
                  });
                }}
              />
            </div>
            <div className='form-group' style={{ height: 750 }}>
              <label htmlFor='htmlDescription'>Description</label>

              <TextEditor
                value={htmlDescription}
                onChange={(data) => {
                  setFormData((d) => ({
                    ...d,
                    htmlDescription: data,
                  }));
                }}
              />
            </div>

            <div className='my-6 card card-body'>
              <h5>
                Attachments
                <small>
                  <a
                    href='#!'
                    style={{ float: 'right' }}
                    className='btn btn-sm btn-primary'
                    onClick={(e) => {
                      e.preventDefault();
                      setFormData({
                        ...formData,
                        attachments: [
                          ...attachments,
                          { description: '', documentUrl: '' },
                        ],
                      });
                    }}
                  >
                    {' '}
                    Add attachment
                  </a>
                </small>
              </h5>
              {attachments.map((each, index) => (
                <Fragment key={index}>
                  <div className='form-group'>
                    <label htmlFor={'attachments' + index}>Description</label>
                    <textarea
                      id={'attachments' + index}
                      rows='2'
                      className='form-control'
                      type='text'
                      value={each.description}
                      onChange={(e) => {
                        const tempArr = [...attachments];
                        tempArr[index].description = e.target.value;
                        setFormData({
                          ...formData,
                          attachments: tempArr,
                        });
                      }}
                    />{' '}
                  </div>
                  <label htmlFor={'attachments' + index}>Attachment</label>
                  <Fs1Input
                    {...{
                      name: 'image',
                      value: each.documentUrl,
                      onChange(e) {
                        const tempArr = [...attachments];
                        tempArr[index].documentUrl = e.target.value;
                        setFormData({
                          ...formData,
                          attachments: tempArr,
                        });
                      },
                      placeholder: 'Attachment',
                    }}
                  />{' '}
                  <small className='form-text text-muted'>
                    <a
                      href='#!'
                      onClick={(e) => {
                        e.preventDefault();
                        const tempArr = [...attachments];
                        tempArr.splice(index, 1);
                        setFormData({
                          ...formData,
                          attachments: tempArr,
                        });
                      }}
                    >
                      Delete
                    </a>
                  </small>
                </Fragment>
              ))}
            </div>
          </div>
          <div className='col-md-4 col-xs-12'>
            <div className='form-group'>
              <Fs1Input
                {...{
                  accept: 'image/*',
                  name: 'image',
                  value: image,
                  onChange: onChange,
                  placeholder: 'Article Image',
                }}
              />
            </div>
            <div className='form-group' style={{ height: 200 }}>
              <label htmlFor='promotionalMessage'>
                Promotional message
              </label>
              <TextEditor
                mini={true}
                value={promotionalMessage}
                onChange={(data) => {
                  setFormData((d) => ({
                    ...d,
                    promotionalMessage: data,
                  }));
                }}
              />
            </div>

            <div className='form-group'>
              <label htmlFor='categories'>Categories </label>
              <Select
                isMulti
                defaultValue={setOptions(categories || [])}
                placeholder='Article categories'
                options={setOptions(categoriesList || [])}
                onChange={(val) => {
                  const tempArr = val?.map((e) => e.value) || [];
                  onChange({
                    target: { name: 'categories', value: tempArr },
                  });
                }}
              />
            </div>
            <br />
          </div>
        </div>
        <Prompt
          when={didSomethingChange}
          message={(location) => `Do you want to leave without saving?`}
        />
      </div>
    </form>
  );
};

export default ArticleForm;
