import React, { Fragment } from 'react';
import Fs1Input from '../../Layout/FsInput';

const Profile = ({ formData, onChange, setFormData, categories }) => {
  const {
    email,
    primaryPhone,
    mobilePhone,
    fax,
    website,
    scheduleLink,
    primaryLocation,
    secondaryLocations,
    name,
    title,
    founderOf,
    smallMessage,
    degreeOrCertificate,
    yiddishName,
    practiceSince,
  } = formData;

  const categoryOptions = (filter = (e) => true) =>
    categories &&
    [{ _id: '', name: 'Please select a category' }, ...categories]
      .filter(filter)
      .map((e, i) => (
        <option key={i} value={e._id}>
          {e.name}
        </option>
      ));
  const { categories: profileCategories, image } = formData;

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const validateUrl = (value) => {
    return /^((https?):\/\/)?[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?/.test(
      value
    );
  };
  const classNames = (ojb) => {
    let classes = '';
    for (let key in ojb) {
      if (ojb[key]) classes += ' ' + key;
    }
    return classes;
  };
  const onChangePrimaryLocation = (e) => {
    const tempObj = { ...primaryLocation };
    tempObj[e.target.name] = e.target.value;
    setFormData({
      ...formData,
      primaryLocation: tempObj,
    });
  };
  const onChangeSecondaryLocations = (e, i) => {
    const tempObj = { ...secondaryLocations[i] };
    tempObj[e.target.name] = e.target.value;
    const tempArr = [...secondaryLocations];
    tempArr[i] = tempObj;
    setFormData({
      ...formData,
      secondaryLocations: tempArr,
    });
  };

  return (
    <div className='row'>
      <div className='col-md-6 col-xs-12'>
        <div className='my-3 card card-body'>
          <h5>About</h5>
          <div className='form-group'>
            <label htmlFor='name'>Your name</label>
            <input
              type='text'
              className='form-control'
              id='name'
              name='name'
              value={name}
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='yiddishName'>יודישע נאמען</label>
            <input
              type='text'
              className='form-control'
              id='yiddishName'
              name='yiddishName'
              value={yiddishName}
              onChange={onChange}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='title'>Title</label>
            <input
              type='text'
              className='form-control'
              id='title'
              value={title}
              onChange={onChange}
              name='title'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='founderOf'>Founder Of</label>
            <input
              type='text'
              className='form-control'
              id='founderOf'
              value={founderOf}
              onChange={onChange}
              name='founderOf'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='smallMessage'>Small message about yourself</label>
            <textarea
              className='form-control'
              id='smallMessage'
              value={smallMessage}
              onChange={onChange}
              rows='9'
              name='smallMessage'
            />
          </div>
          <div className='row'>
            <div className='form-group col-md-6'>
              <label htmlFor='degreeOrCertificate'>Degree or certificate</label>
              <input
                type='text'
                className='form-control'
                id='degreeOrCertificate'
                value={degreeOrCertificate}
                onChange={onChange}
                name='degreeOrCertificate'
              />
            </div>

            <div className='form-group col-md-6'>
              <label htmlFor='practiceSince'>Practice Since</label>
              <input
                type='text'
                className='form-control'
                id='practiceSince'
                value={practiceSince}
                name='practiceSince'
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className='my-3 card card-body'>
          <div className='form-group'>
            <label htmlFor=''>Profile Image</label>
            <Fs1Input
              {...{
                accept: 'image/*',
                name: 'image',
                value: image,
                onChange: onChange,
                placeholder: 'Select Image',
              }}
            />
          </div>
        </div>
        <div className='my-3 card card-body'>
          <div className='form-group'>
            <h5 className='mb-4'>
              Categories
              <small>
                <a
                  href='#!'
                  className={classNames({
                    'btn btn-sm btn-primary': true,
                    disabled:
                      categories &&
                      !categories.filter(
                        (e) => !profileCategories.includes(e.id)
                      ).length > 0,
                  })}
                  style={{ float: 'right' }}
                  onClick={(e) => {
                    e.preventDefault();
                    setFormData({
                      ...formData,
                      categories: [...profileCategories, ''],
                    });
                  }}
                >
                  Add category
                </a>
              </small>
            </h5>
            {profileCategories.map((category, index) => (
              <Fragment key={index}>
                <select
                  className='custom-select'
                  value={category}
                  onChange={(e) => {
                    const tempArr = [...profileCategories];
                    tempArr[index] = e.target.value;
                    setFormData({
                      ...formData,
                      categories: tempArr,
                    });
                  }}
                >
                  {categoryOptions(
                    (e) =>
                      !profileCategories.includes(e.id) || category === e.id
                  )}
                </select>
                <small className='form-text text-muted'>
                  <a
                    href='#!'
                    onClick={(e) => {
                      e.preventDefault();
                      const tempArr = [...profileCategories];
                      tempArr.splice(index, 1);
                      setFormData({
                        ...formData,
                        categories: tempArr,
                      });
                    }}
                  >
                    Delete
                  </a>
                </small>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className='col-md-6 col-xs-12'>
        <div className='my-3 card card-body'>
          <h5>Contact information</h5>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              className={classNames({
                'is-invalid': !validateEmail(email) && email,
                'form-control': true,
              })}
              id='email'
              name='email'
              value={email}
              onChange={onChange}
            />
            <div className='invalid-feedback'>Invalid email</div>
          </div>
          <div className='form-group'>
            <label htmlFor='primaryPhone'>Primary phone number</label>
            <input
              type='tel'
              className='form-control'
              id='primaryPhone'
              value={primaryPhone}
              onChange={onChange}
              name='primaryPhone'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='mobilePhone'>Mobile phone number</label>
            <input
              type='tel'
              className='form-control'
              id='mobilePhone'
              value={mobilePhone}
              onChange={onChange}
              name='mobilePhone'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='fax'>Fax number</label>
            <input
              type='tel'
              className='form-control'
              id='fax'
              value={fax}
              onChange={onChange}
              name='fax'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='website'>Website</label>
            <input
              type='text'
              className={classNames({
                'is-invalid': !validateUrl(website) && website,
                'form-control': true,
              })}
              id='website'
              value={website}
              onChange={onChange}
              name='website'
            />
            <div className='invalid-feedback'>Invalid url</div>
          </div>
          <div className='form-group'>
            <label htmlFor='scheduleLink'>Schedule link</label>
            <input
              type='text'
              id='scheduleLink'
              className={classNames({
                'is-invalid': !validateUrl(scheduleLink) && scheduleLink,
                'form-control': true,
              })}
              value={scheduleLink}
              onChange={onChange}
              name='scheduleLink'
            />
            <div className='invalid-feedback'>Invalid url</div>
          </div>
        </div>
        <div className='my-3 card card-body'>
          <h5>
            Primary location
            <small>
              <a
                href='#!'
                style={{ float: 'right' }}
                className={'btn btn-primary btn-small'}
                onClick={(e) => {
                  e.preventDefault();
                  const tempArr = [
                    ...secondaryLocations,
                    {
                      locationName: '',
                      street: '',
                      city: '',
                      zip: '',
                      state: '',
                      country: '',
                      locationPhoneNumber: '',
                    },
                  ];
                  setFormData({
                    ...formData,
                    secondaryLocations: tempArr,
                  });
                }}
              >
                Add location
              </a>
            </small>
          </h5>
          {/* <AddressForm
            {...{ onChange: onChangePrimaryLocation, address: primaryLocation }}
          /> */}
          {/* {(
            onChange = onChangeSecondaryLocations,
            address = primaryLocation,
            index
          ) =>
            (<> */}
          <div className='form-group'>
            <label htmlFor='primaryLocation.street'>Street</label>
            <input
              type='text'
              className='form-control'
              id={'address.street'}
              value={primaryLocation.street}
              onChange={(e) => onChangePrimaryLocation(e)}
              name='street'
            />
          </div>
          <div className='form-group'>
            <label htmlFor={'primaryLocation.city'}>City</label>
            <input
              type='text'
              className='form-control'
              id={'primaryLocation.city'}
              value={primaryLocation.city}
              onChange={(e) => onChangePrimaryLocation(e)}
              name='city'
            />
          </div>
          <div className='form-group'>
            <label htmlFor={'primaryLocation.state'}>State</label>
            <input
              type='text'
              className='form-control'
              id={'primaryLocation.state'}
              value={primaryLocation.state}
              onChange={(e) => onChangePrimaryLocation(e)}
              name='state'
            />
          </div>
          <div className='form-group'>
            <label htmlFor={'primaryLocation.zip'}>Zip</label>
            <input
              type='text'
              className='form-control'
              id={'primaryLocation.zip'}
              value={primaryLocation.zip}
              onChange={(e) => onChangePrimaryLocation(e)}
              name='zip'
            />
          </div>
          <div className='form-group'>
            <label htmlFor={'primaryLocation.locationPhoneNumber'}>
              Location phone number
            </label>
            <input
              type='text'
              className='form-control'
              id={'primaryLocation.locationPhoneNumber'}
              value={primaryLocation.locationPhoneNumber}
              onChange={(e) => onChangePrimaryLocation(e)}
              name='locationPhoneNumber'
            />
          </div>
          {/* </>)()
          } */}
        </div>
        {secondaryLocations.map((address, index) => (
          // <AddressForm
          //   key={index}
          //   {...{ onChange: onChangeSecondaryLocations, address, index }}
          // />
          <div className='my-3 card card-body' key={index}>
            <div className='form-group'>
              <label htmlFor={'address' + index + 'street'}>Street</label>
              <input
                type='text'
                className='form-control'
                id={'address' + index + 'street'}
                value={address.street}
                onChange={(e) => onChangeSecondaryLocations(e, index)}
                name='street'
              />
            </div>
            <div className='form-group'>
              <label htmlFor={'address' + index + 'city'}>City</label>
              <input
                type='text'
                className='form-control'
                id={'address' + index + 'city'}
                value={address.city}
                onChange={(e) => onChangeSecondaryLocations(e, index)}
                name='city'
              />
            </div>
            <div className='form-group'>
              <label htmlFor={'address' + index + 'state'}>State</label>
              <input
                type='text'
                className='form-control'
                id={'address' + index + 'state'}
                value={address.state}
                onChange={(e) => onChangeSecondaryLocations(e, index)}
                name='state'
              />
            </div>
            <div className='form-group'>
              <label htmlFor={'address' + index + 'zip'}>Zip</label>
              <input
                type='text'
                className='form-control'
                id={'address' + index + 'zip'}
                value={address.zip}
                onChange={(e) => onChangeSecondaryLocations(e, index)}
                name='zip'
              />
            </div>
            <div className='form-group'>
              <label htmlFor={'address' + index + 'locationPhoneNumber'}>
                Location phone number
              </label>
              <input
                type='text'
                className='form-control'
                id={'address' + index + 'locationPhoneNumber'}
                value={address.locationPhoneNumber}
                onChange={(e) => onChangeSecondaryLocations(e, index)}
                name='locationPhoneNumber'
              />
            </div>
            {index !== undefined && (
              <small className='form-text text-muted'>
                <a
                  href='#!'
                  onClick={(e) => {
                    e.preventDefault();
                    const tempArr = [...secondaryLocations];
                    tempArr.splice(index, 1);
                    setFormData({
                      ...formData,
                      secondaryLocations: tempArr,
                    });
                  }}
                >
                  Delete this location
                </a>
              </small>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Profile;
