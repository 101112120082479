import React, { useState, createContext } from 'react';
import api from '../utils/api';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from './auth';
import { ToastContext } from './toasts';
import { withRouter } from 'react-router-dom';

export const ProfileContext = createContext();

const ProfileState = ({ children, history }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { addToast } = useContext(ToastContext);
  const [profiles, setProfiles] = useState(null);
  const [publicProfiles, setPublicProfiles] = useState(null);
  const [profile, setProfile] = useState(null);

  const getProfiles = async (
    params = { select: 'image status categories name', populate: 'categories' }
  ) => {
    try {
      const profiles = await api.get('/admin/profiles', {
        params,
      });
      setProfiles(profiles.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getPublicProfiles = async (
    params = { select: 'image status categories name', populate: 'categories' }
  ) => {
    try {
      const profiles = await api.get('/profiles', {
        params,
      });
      setPublicProfiles(profiles.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getProfileById = async (id) => {
    try {
      const profile = await api.get('/admin/profiles/' + id);
      setProfile(profile.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateProfile = async (id, data) => {
    try {
      const profile = await api.put('/admin/profiles/' + id, data);
      setProfile(profile.data);
      addToast('Profile Updated', 'success');
      getProfiles();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const createProfile = async (data) => {
    try {
      const profile = await api.post('/admin/profiles', data);
      addToast('Profile Created', 'success');
      getProfiles();
      setProfile(profile.data);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const searchProfiles = async (
    term,
    params = { select: 'image status categories name', populate: 'categories' }
  ) => {
    try {
      const profiles = await api.get('/admin/profiles/search', {
        params: {
          ...params,
          q: term,
        },
      });
      setProfiles(profiles.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setProfiles(null);
      setProfile(null);
    }
  }, [isAuthenticated]);

  return (
    <ProfileContext.Provider
      value={{
        profiles,
        getProfiles,
        getProfileById,
        searchProfiles,
        profile,
        getPublicProfiles,
        publicProfiles,
        updateProfile,
        createProfile,
        setProfile,
        setProfiles,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default withRouter(ProfileState);
