import React, { createContext } from 'react';
import api from '../utils/api';
import { toast } from 'react-toastify';

export const ToastContext = createContext();

const ToastState = ({ children }) => {
  const addToast = (message, type) => {
    toast[type](message);
  };

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      console.log(error.response);
      const message =
        error?.response?.data?.error?.message || error?.response?.statusText;
      if (message !== 'permissions denied1') addToast(message, 'error');

      return Promise.reject(error);
    }
  );

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastState;
