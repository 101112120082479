import React, { useState, createContext } from 'react';
import api from '../utils/api';
import { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from './auth';
import { ToastContext } from './toasts';
import { withRouter } from 'react-router-dom';

export const ArticleContext = createContext();

const ArticleState = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { addToast } = useContext(ToastContext);
  const [articles, setArticles] = useState(null);
  const [article, setArticle] = useState(null);

  const getArticles = async (
    params = { select: 'image status categories title', populate: 'categories' }
  ) => {
    try {
      const articles = await api.get('/admin/articles', {
        params,
      });
      setArticles(articles.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getArticleById = async (id) => {
    try {
      const article = await api.get('/admin/articles/' + id);
      setArticle(article.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateArticle = async (id, data) => {
    try {
      const article = await api.put('/admin/articles/' + id, data);
      setArticle(article.data);
      addToast('Article Updated', 'success');
      getArticles();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const createArticle = async (data) => {
    try {
      const article = await api.post('/admin/articles', data);
      addToast('Article Created', 'success');
      getArticles();
      setArticle(article.data);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const searchArticles = async (
    term,
    params = { select: 'image status categories title', populate: 'categories' }
  ) => {
    try {
      const articles = await api.get('/admin/articles/search', {
        params: {
          ...params,
          q: term,
        },
      });
      setArticles(articles.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      setArticles(null);
      setArticle(null);
    }
  }, [isAuthenticated]);

  return (
    <ArticleContext.Provider
      value={{
        articles,
        getArticles,
        getArticleById,
        searchArticles,
        article,
        updateArticle,
        createArticle,
        setArticle,
      }}
    >
      {children}
    </ArticleContext.Provider>
  );
};

export default withRouter(ArticleState);
