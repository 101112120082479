import React from 'react';
import CategoryHeader from './CategoryHeader';
import { Link, Prompt, useHistory, useParams } from 'react-router-dom';
import { useGlobalContext } from '../../../context';
import Fs1Input from '../../Layout/FsInput';
import { useEffect } from 'react';

const CategoryForm = ({
  category,
  textFields,
  setTextFields,
  didSomethingChange,
  setDidSomethingChange,
}) => {
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    // i placed this history.push actions in this affect because I only want to run it if !didSomeThingChange to avoid the react-router-dom Prompt component

    //after new gets submitted
    if (!didSomethingChange && category && params.category_id === 'new')
      history.push('/categories/' + category._id);
  }, [didSomethingChange]);

  const onChangeTextFields = (e) => {
    if (!didSomethingChange) setDidSomethingChange(true);
    setTextFields({
      ...textFields,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const { name, image, description } = textFields;

  const {
    categories: { updateCategory, createCategory, deleteCategory },
  } = useGlobalContext();

  const onSubmit = async (e) => {
    e.preventDefault();
    let res;
    if (category) {
      res = await updateCategory(category._id, textFields);
    } else {
      res = await createCategory(textFields);
    }
    if (res) {
      setDidSomethingChange(false);
    }
  };

  const onDelete = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        'Are you sure you want to delete? This will also delete the category from all profiles'
      )
    ) {
      deleteCategory(category._id);
      setDidSomethingChange(false);
    }
  };

  return (
    <form>
      <div className='container'>
        <CategoryHeader
          {...{
            didSomethingChange,
            onSubmit,
            onDelete,
            category,
          }}
        />
        <div className='row'>
          <div className='col-md-8 col-xs-12'>
            <h3>Category</h3>
            <div className='form-group'>
              <label htmlFor='name'>Category name </label>
              <input
                className='form-control'
                id='name'
                value={name}
                onChange={onChangeTextFields}
                name='name'
              />
            </div>
            <div className='form-group'>
              <label htmlFor='description'>Category description </label>
              <textarea
                className='form-control'
                id='description'
                value={description}
                onChange={onChangeTextFields}
                rows='12'
                name='description'
              />
            </div>
          </div>
          <div className='col-md-4 col-xs-12'>
            <div className='form-group'>
              <Fs1Input
                {...{
                  accept: 'image/*',
                  name: 'image',
                  value: image,
                  onChange: onChangeTextFields,
                  placeholder: 'Category Image',
                }}
              />
            </div>
          </div>
        </div>
        <Prompt
          when={didSomethingChange}
          message={(location) => `Do you want to leave without saving?`}
        />
      </div>
      {category && (
        <Link
          className='btn btn-primary'
          type='button'
          to={'/profiles?categories=' + category._id}
        >
          See all profiles of this category
        </Link>
      )}
    </form>
  );
};

export default CategoryForm;
