import React from 'react';

const Footer = () => {
  return (
    <div
      className='bg-secondary text-white'
      style={{
        minHeight: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 50,
      }}
    >
      <div className='container'>Torah Education Admin</div>
    </div>
  );
};

export default Footer;
